import React from 'react';
import { Button } from '@a-cloud-guru/kermit';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';

const SYNC_SAML_DOMAINS = gql`
  query syncSamlDomains {
    syncSamlDomains
  }
`;

const SyncEmailDomainsButton = () => {
  const [syncDomains, { loading, data, error }] = useLazyQuery(SYNC_SAML_DOMAINS);

  const handleClick = () => {
    syncDomains();
    if (loading) {
      console.log('Loading...');
    }
    if (data) {
      console.log('Data:', data);
    }
    if (error) {
      console.error('Error:', error);
    }
  };

  return (
    <StyledButton type="primary" onClick={handleClick} disabled={loading}>
      {loading ? 'Syncing...' : 'Sync domains'}
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  height: 36px;
  width: 150px;
  font-weight: 600;
`;

export { SyncEmailDomainsButton };